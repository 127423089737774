import * as React from 'react';
import { Router } from '@reach/router';
import PartnershipDetail from './partnerships-detail';

const Tools = () => {
  return (
    <div>
      <Router>
        <PartnershipDetail path="/partnerships/:slug" />
      </Router>
    </div>
  );
};

export default Tools;
